

































































import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import { Watch } from 'vue-property-decorator';
import API, { Types } from '@/modules/API';
import { Reports, User, Global, Props } from '@/store';
import GoogleMap from './components/map/GMap.vue';
import PropUtils from '@/modules/PropUtils';
import DeviceNetworkingInfo from './components/DeviceNetworkingInfo.vue';
import DeviceMetadata from './components/DeviceMetadata.vue';
import NetworkToolbar from './components/NetworkToolbar.vue';
import Utils from '@/modules/Utils';
import moment from 'moment';
import momentTZ from 'moment-timezone';

@Component({
  components: {
    ConfirmDialog,
    GoogleMap,
    DeviceNetworkingInfo,
    DeviceMetadata,
    NetworkToolbar
  }
})
export default class NetworkManagement extends Vue {
    
  @Global.State('isMobile') isMobile;
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Reports.State('reportsList') devices;
  @Reports.State('devicesCount') devicesCount;
  @Props.State('list') projectProperties;
  @Props.Action('editProp') edit;
  @User.State('project') project;
  
  deviceInformation = false;
  selectedDevices = [];
  currentDevice = null;
  markerFocused = null;

  without_provider = 'Without Provider';
  trackers = [];
  devicesMetadata = new Map();
  currentMapDevices = [];
  communicated = [];
  three_days_missing = [];
  never_communicated = [];
  not_commissioned = [];
  sai_req_fault = [];
  cabinetCommunicationLevels = [];
  fixtureCommunicationLevels = [];
  last_time_update = '';
  network_providers = {};
  selected_providers = [];
  loading = true;
  antennaLocation = null;
  networkProvidersProperty = null;
  no_data = false;

  filters = {
    communicated: { id: 'communicated', name: 'Quality Grade', color: 'green', color_code: '#4CAF50', count: 0, flex: '2'},
    three_days_missing: { id: 'three_days_missing', name: '3 Days Missing', color: 'purple', color_code: '#9C27B0', count: 0, flex: '3'},
    never_communicated: { id: 'never_communicated', name: 'Never Communicated', color: 'black', color_code: '#000000', count: 0, flex: '3'},
    not_commissioned: { id: 'not_commissioned', name: 'Not Commissioned', color: 'indigo', color_code: '#3F51B5', count: 0, flex: '3'},
    sai_req_fault: { id: 'sai_req_fault', name: 'SAI Req Fault', color: 'brown', color_code: '#63423D', count: 0, flex: '3'}
  };

  more_legend_icons = [
    { text: 'Never Communicated', color: 'black', color_code: '#000000'},
    { text: '3 Days Missing', color: 'purple', color_code: '#9C27B0'}
  ];

  mounted() {
    this.setPageTitle('Network Management');
    this.generatePageData();
  }

  @Watch('devices')
  @Watch('devicesCount')
  generatePageData(){
    if (this.devices.length !== this.devicesCount) return;
    this.loading = true;
    this.selectedDevices = [];
    this.markerFocused = null;
    this.antennaLocation = null;
    this.updateCommunicationConditioning();
    this.setNetworkProviders();
    this.networkProvidersProperty 
      ? this.getDevices()
      : this.no_data = true;
    this.loading = false;
  }

  setNetworkProviders(){
    const providers = PropUtils.getProperty(this.projectProperties, 'project.network_providers');
    this.networkProvidersProperty = providers ? providers.value : null;
    if (this.networkProvidersProperty){
      this.last_time_update = this.networkProvidersProperty.timestamp;
      this.networkProvidersProperty.providers.forEach((provider) => this.network_providers[provider] = { provider, devices: [], count: 0});
      this.network_providers[this.without_provider] = { provider: this.without_provider, devices: [], count: 0};
    }else{
      console.log('There is no project.network_providers property in this project');
    }
  }

  showDeviceNetworking(device){
    this.setAntennaLocation(null);
    this.setCurrentDevice(device);
    this.setSelectedDevices([]);
  }

  setSelectedProviders(items){
    this.selected_providers = [...items];
    this.setCircledMapDevices();
  }

  setCircledMapDevices(){
    let devices = [];
    this.selected_providers.forEach((provider) => devices = devices.concat(this.network_providers[provider].devices));
    this.setSelectedDevices(devices);
  }

  setCurrentMapDevices(devices, add){
    this.currentMapDevices = add 
      ? [...this.currentMapDevices, ...devices]
      : this.currentMapDevices.filter((device) => !devices.includes(device));
  }

  setCurrentDevice(device){
    this.currentDevice = device;
  }

  setSelectedDevices(selected){
    this.selectedDevices = [];
    this.selectedDevices = selected;
  }

  setAntennaLocation(location){
    this.antennaLocation = location ? {...location} : location;
  }

  focusedToMarker(device) {
    this.markerFocused = Object.assign({}, device);
  }

  showPopup(device){
    this.$refs.gmap['showCurrentInfoWindow'](device);
  }

  updateCommunicationConditioning() {
    let communicationColorTable = PropUtils.getProperty(this.projectProperties, 'dashboard.communication_monitoring');
    communicationColorTable = communicationColorTable ? communicationColorTable.value : null;
    if (communicationColorTable){
      this.cabinetCommunicationLevels = communicationColorTable['Cabinet Monitors'];
      this.fixtureCommunicationLevels = communicationColorTable.Fixtures;
    }else{
      console.log(`Couldnt find communication_monitoring property for this project`);
    }
  }

  getDevices() { 
    const tondo_and_cabinets = this.devices.filter((device) => Utils.hasTondoClass(device.class_name) || Utils.hasCabinetClass(device.class_name));
    tondo_and_cabinets.forEach((device) => {
      const commission = device['meta.commission'];

      if (!commission.commissioned){
        this.not_commissioned.push({ title: device.id, subtitle: device.name });
        return;
      }
  
      const networkProperty = PropUtils.parseProperty('dashboard.communication_monitoring', device);
      const metadata = device['meta.device'];
      const utcSmodified = moment.utc(device.smodified_ts * 1000).format();
      const timezoneSmodified = momentTZ.tz(utcSmodified, this.projectTimezone).format('DD.MM.YYYY HH:mm:ss');
      let deviceData = { id: device.id, group: '' , name: device.name, cabinetId: metadata.cabinet_id, poleNumber: metadata.pole_number, smodified: timezoneSmodified };

      if (networkProperty.hasOwnProperty('Online')){
        deviceData = {...deviceData, ...networkProperty };
        if (device['monogoto_status'] && device['monogoto_status'] === 'SAI') {
          this.sai_req_fault.push({...device, icon_color: this.filters['sai_req_fault'].color_code});
          deviceData.group = 'SAI Req Fault';
        }
        else if (networkProperty.Online){
          this.communicated.push(device);
          this.currentMapDevices.push(device);
          deviceData.group = 'Quality Grade';
        }
        else{
          this.three_days_missing.push({...device, icon_color: this.filters['three_days_missing'].color_code});
          deviceData.group = '3 Days Missing';
        }
        const network_provider = networkProperty.Network;
        if (network_provider && this.network_providers[network_provider]){
          this.network_providers[network_provider].devices = this.network_providers[network_provider].devices.concat([device.id]);
        }else{
          this.network_providers[this.without_provider].devices = this.network_providers[this.without_provider].devices.concat([device.id]);
        }
      }else{
        this.never_communicated.push({...device, icon_color: this.filters['never_communicated'].color_code});
        this.network_providers[this.without_provider].devices = this.network_providers[this.without_provider].devices.concat([device.id]);
        deviceData.group = 'Never Communicated';
      }
  
      this.devicesMetadata.set(device.id, deviceData);
    });
    this.updateFiltersCount();
    this.updateProvidersCount();
  }

  updateFiltersCount(){
    Object.values(this.filters).forEach((filter) => {
      filter['count'] = this[filter['id']].length;
    });
  }

  updateProvidersCount(){
    Object.entries(this.network_providers).forEach(([key, value]) => {
      value['count'] = value['devices'].length;
    });
  }
}
